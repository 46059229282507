/* -------------------------------------------------------------------------- */
/*                                Falcon Button                               */
/* -------------------------------------------------------------------------- */
@media print {
  #quotationCard {
    display: none;
  }
  #navbar {
    display: none;
  }
}
input.transparent-input {
  background-color: transparent !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom-color: #e29b38;
  height: 4em;
}

/* prettier-ignore */